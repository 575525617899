//custom standard

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
});

$(function() {
  $('[data-toggle="popover"]').popover();
});


$(document).ready(function() {

  $('body').addClass(self != top ? 'withiframe' : 'none');

  $('.close-icon').on('click',function() {
    $(this).closest('.card').fadeOut();
  })

  setTimeout(function() {
    $('.message').fadeOut('slow');
  }, 6000);

  $('.myTop').click(function(){
    $("html, body").scrollTop(0);
    return false;
  });

});


$(document).ready(function(){

  var back_to_top_button = ['<a href="#top" class="back-to-top"><i class="fas fa-chevron-up"></i></</a>'].join("");
  $("body").append(back_to_top_button)

  $(".back-to-top").hide();

  $(function () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.back-to-top').fadeIn();
      } else {
        $('.back-to-top').fadeOut();
      }
    });

    $('.back-to-top').click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 500);
      return false;
    });
  });

});

